/* reset */
$min-width: 320px; 
$max-width:1822px; 
$base-font-size: 2rem; 
$base-line-height: 1.2; 
$base-font-family: TTFirsNeue, arial, sans-serif; 
$custom-font-family: TTFirsNeue, arial, sans-serif; 

$default-transition:all 0.3s ease-in;

/* main variables */

//text, titles
$text-color:  #1F1F1F;
$title-color: $text-color;
$title-font-family: $base-font-family;
$color-active: #FFDA22;
$color-active-osnova: #1BF0CF;
$line: #B5B5B5;
$color1: #29A690;
$color2: #B5B5B5;

$link-color: $color-active;
$link-color-hover: #fff;
$base-bg: #fff;
$placeholder:$line;

$font-size-h1: 36px;
$line-height-h1: 42px;
$font-size-h2: 32px;
$line-height-h2: 37px;
$font-size-h3: 28px;
$line-height-h3: 32px;
