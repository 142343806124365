.section-exellences {
	padding: 11rem 0 0
}

.exellences {
	margin-top: 10.1rem;
	display: flex;
	flex-wrap: wrap;
	position: relative;

	&__item {
		width: 50%;
		padding: 0 12.9rem 11.9rem 0;

		&:nth-child(even) {
			padding-right: 0;
			padding-left: 12.9rem;
		}
	}

	&__count {
		color: $line;
		font-size: 1.6rem;
		font-weight: 500;
		letter-spacing: 1.6px;
		line-height: 1;
		margin-bottom: 2.1rem;
		text-transform: uppercase;
	}

	&__title {
		font-size: 3.2rem;
		line-height: 1.2;
		font-weight: 500;
		margin-bottom: 1rem;
	}

	&__description {
		line-height: 150%
	}

	&:before {
		content: '';
		display: block;
		width: 1px;
		background: $line;
		position: absolute;
		top: 0;
		bottom: 11.9rem;
		left: 50%;
	}
}

.section-consultation {
	background: #FCFAF1;

	.wrapper {
		padding-top: 6rem;
		padding-bottom: 3rem;
	}

	.h2 {
		font-size: 6rem;
		margin-bottom: 5.1rem;
	}
}

.main-block-form__inner {
	margin: 0 -1.8rem;
	display: flex;
	align-items: center;

	.box-field {
		padding: 0 1.8rem;
		flex: 1 1 100%;
	}

	.btn {
		width: 100%;
		height: 6.4rem;
		line-height: 6.4rem
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="password"] {
		border-color: #1F1F1f;
		background: rgba(255, 255, 255, 0.20);

		&.error {
			border-color: #FF5050
		}

		&::-webkit-input-placeholder {
			color: $text-color;
		}

		&::-moz-placeholder {
			color: $text-color;

		}

		&:-moz-placeholder {
			color: $text-color;
		}

		&:-ms-input-placeholder {
			color: $text-color;
		}
	}
}

.form-wrapper {
	position: relative;

}

.alert-form-wrap {
	display: none;
}

.alert-form {
	padding-bottom: 1.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #FCFAF1;

	&__text {
		width: 50%
	}

	&__img {
		width: 50%;
		text-align: center;
	}

	.h2 {
		text-align: left;
		margin-bottom: 2.1rem;
	}
}

.alert-text {
	font-weight: 400;
	font-size: 2rem;
	line-height: 1.5;
	max-width: 71rem;
}
.img-osnova {
	display: none;
}
.product-about-downloads {
	&__item {
		a {
			padding-left: 25px;
			position: relative;
			min-height: 25px;
			color: $text-color;
			display: inline-flex;
			align-items: center;

			i {
				font-size: 25px;
				margin-right: 10px;
				left: 0;
				top: 0;
			}
		}

	}

	&__item+&__item {
		margin-top: 10px;
	}
}

.section-video-product {
	padding: 3.1rem 0 7rem
}

.section-create-project {
	background: #FCFAF1;
	margin-top: 6.2rem;
	padding: 7.2rem 0 70px
}

.create-project {
	display: flex;

	&__details {
		width: 57.5%;
	}

	&__info {
		width: 42.5%;
		padding-left: 3.6rem
	}

	&__head {
		font-weight: 500;
		font-size: 2.4rem;
		margin-bottom: 3.8rem;
	}
}

.create-project-block {
	background: #fff;
	padding: 4.3rem 5.9rem 2.8rem 5.9rem;
	margin-bottom: .8rem
}

.box-field-wrap {
	margin: 0 -2.5rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.box-field_project,
.box-field_project-checkbox {
	padding: 0 2.5rem;
	max-width: 43rem;
	margin-bottom: 2.8rem;
	width: 50%;
}
.box-field_project {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	[type="text"], [type="tel"], [type="email"], [type="password"] {
	    height: 5.6rem;
	}
}

.box-field_project-checkbox {
	margin-bottom: 15px;
	min-height: 3.3rem
}



.box-field_label {
	font-size: 1.6rem;
	font-weight: 500;
	min-height: 3rem;
	margin-bottom: .5rem;
}


.create-project__submit {
	margin-top: 20px;

	.btn {
		width: 31.3rem;
		max-width: 100%
	}
}

.new-projects {
	max-height: 80rem;
	overflow: auto;

	&__item {
		min-height: 21.5rem;
		display: flex;
		border: 1px solid $line;
		background: #FFF;
		margin-bottom: 1.6rem;

		&.expanded {
			padding-bottom: 45px;
			position: relative;

			.new-projects__more {
				position: absolute;
				background: #F3F1D9;
				left: 0;
				right: 0;
				bottom: 0;
				justify-content: center;
				align-items: center;
				padding: 10px 10px;

				i {
					@extend .icon-minus;
					font-size: 14%
				}
			}

			.new-projects__detail {
				display: flex;
			}
		}
	}

	&__title {
		font-size: 2.4rem;
		line-height: 1.2;
		font-weight: 500;
		z-index: 1;
		border-bottom: 1px solid $line;

		span {
			border-bottom: 1px solid transparent;
			transition: border-bottom-color .6s ease
		}
	}

	&__img {
		background: #FCFAF1;
		width: 30%;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			max-height:16.5rem;
			max-width: 100%;
		}
	}

	&__details {
		margin-top: 2.2rem;
	}

	&__detail {
		font-size: 1.6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&-value {
			font-weight: 500;
			padding-left: 1.4rem;
			text-align: right;
		}

		&+& {
			margin-top: 15px;
		}
	}

	&__text {
		font-size: 1.6rem;
		line-height: 1.5;
		max-width: 28rem;

		p {
			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	&__head {
		width: 70%;
		padding: 15px 30px 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__btn {
		margin-top: 25px;

		.btn {
			width: 100%;
			height: 6rem;
			line-height: 6rem
		}
	}

	&__more {
		margin-top: 20px;
		display: inline-flex;
		align-items: baseline;
		font-size: 16px;
		font-weight: 500;
		cursor: pointer;

		i {
			margin-left: 10px;
			font-size: 85%;
			transition: margin-left .5s ease;
		}

		&:hover {
			i {
				margin-left: 4.2rem
			}
		}
	}

	&::-webkit-scrollbar {
		width: .5rem;
	}

	&::-webkit-scrollbar-track {
		background: #E4E4E4
	}

	&::-webkit-scrollbar-thumb {
		background-color: $line;
		outline: 0;
	}
}


.new-projects__item_placeholder {
	.new-projects__title {
		border-bottom: 1px solid transparent;
	}

	.new-projects__more {
		display: none;
	}
}

.created-projects {
	&-link {
		margin-bottom: .8rem;
		padding: 2.2rem 4.6rem;
		background: #fff;
		border-left: 1.2rem solid #FFDA22;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__remove,
		&__edit {
			display: inline-flex;
			align-items: center;
			font-size: 1.6rem;
			margin-right: 7.4rem;
			font-weight: 500;
			cursor: pointer;

			i {
				margin-right: 15px;
			}

			&:hover {
				color: $color-active;
			}
		}
		&__remove {
			margin-right: 3.5rem;
		}
		&__edit {
			padding: .9rem 1.9rem;
			border: 1px solid $text-color;
			cursor: pointer; transition: color .7s ease
		}

		&__more {
			padding: 10px;
			position: absolute;
			right: 35px;
			top: 50%;
			transform: translateY(-50%);
			color: $text-color;
		}

		&__btns {
			display: inline-flex;
			align-items: center;
			margin-left: 2rem;
		}
	}
}

.create-project_2 {
	.new-projects__img {
		background: $text-color
	}
}

.box-field_project-date {
	padding: 0 2.5rem;
	margin-bottom: 2.8rem;
	width: 50%;

	.box-field__input {
		position: relative;

		input {
			padding-right: 50px;
			z-index: 1
		}

		i {
			position: absolute;
			right: 10px;
			top: 1.8rem;
			color: $line;
			font-size: 25px;
			z-index: 2;
			pointer-events: none
		}
	}
}



.video-el {
	display: flex;
	justify-content: center;
	position: relative;
	max-height: calc(100vh - 11rem);
	img {
		width: 100%;
		vertical-align: top;
	}

	&.active {
		img {
			display: none;
		}

		.floating-arrow {
			display: none;
		}
/*
		&:before {
			content: '';
			display: block;
			padding-top: 56.2%
		}*/
	}

	iframe {
		//width: auto;
		//height: auto;
/*		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;*/
	}
}

.floating-arrow {
	font-size: 1.8rem;
	text-transform: uppercase;
	background: #FFDA22;
	height: 20rem;
	width: 20rem;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;

	display: flex;
	align-items: center;
	justify-content: center;
	color: $text-color;
}

.first-position {
	.floating-arrow {
		transform: translate(-50%, -50%);
	}
}


.section-more-projects {
	padding: 8rem 0 70px
}

.projects {
	margin-bottom: 40px;

	&__item {
		position: relative;
		text-decoration: none;
		color: $text-color;

		&:hover {
			.projects__title span {
				border-bottom-color: $text-color;
			}
		}
	}

	&__title {
		font-size: 2.4rem;
		line-height: 1.2;
		font-weight: 500;
		z-index: 1;
		padding-bottom: 2px;

		span {
			border-bottom: 1px solid transparent;
			transition: border-bottom-color .6s ease
		}
	}

	&__img {
		margin-bottom: 2.4rem;
		background: #FCFAF1;
		width: 100%;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: relative;
			padding-top: 86%
		}

		img {
			max-height: 95%;
			max-width: 100%;
			position: absolute;
			border-radius: 1.6rem 1.6rem 0px 0px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__category {
		color: $line;
		font-size: 1.6rem;
		padding-left: 2.3rem;
		margin-bottom: 2.4rem;
		letter-spacing: 1.6px;
		text-transform: uppercase;
		position: relative;
		z-index: 0;

		&:before {
			content: '';
			display: block;
			width: 1px;
			top: -1px;
			bottom: 1px;
			position: absolute;
			left: 0;
			background: $color-active
		}

	}

}

.navs-project-slider {
	display: flex;
	align-items: center;
	justify-content: center;
}


.projects-slider {
	.slick-prev {
		margin-right: 20px;
		order: 1;
	}

	.dots-project-slider {
		order: 2;
	}

	.slick-next {
		order: 3;
		margin-left: 20px;
	}

	.slick-slide {
		margin: 0 1.7rem;
	}

	.slick-list {
		margin: 0 -1.7rem;
	}
}

.more-projects_2 {
	.projects__img {
/*		display: flex;
		align-items: flex-end;
		justify-content: flex-end;

		&:before {
			display: none;
		}*/

		img {
			max-height: 100%;
/*			position: static;
			transform: none;*/
		}
	}
}


.table-spare {
	overflow: auto;

	table {
		min-width: 65rem;
		width: 100%;
		table-layout: fixed;
		text-align: center;
	}

	p {
		padding: 0;
		display: inline;
	}

	th {
		font-weight: 500;
		vertical-align: middle;
	}

	tr:first-child th {
		background: #FAFAFA;
	}
	ul, ol {
		margin: 0;
		display: inline-block;
		text-align: left
	}
	th,
	td {
		border-right: 1px dashed #CDCDCD;
		padding: 1.7rem 1.7rem 1.7rem;
		vertical-align: middle;

		&:last-child {
			border-right-width: 0;
		}
	}

	td {
		padding: 1.7rem 1.7rem 1.7rem;

	}

	tr:nth-child(odd) td {
		background: #FAFAFA;
	}

	.icon-minus {
		font-size: 30%
	}
}
.product-about-tabs .table-spare ul li {
    margin-bottom: 7px;
    &:last-child {
    	margin-bottom: 0;
    }
}
.interesting-projects {
	margin: 0 -1.7rem;
	display: flex;
	flex-wrap: wrap;

}

.new-one-project {
	background: #fff;
	margin-top: 15px;
	padding: 2.4rem 2rem;
	font-size: 1.8rem;
	color: $text-color;
	text-transform: uppercase;
	display: flex;
	align-items: baseline;
	justify-content: center;

	i {
		margin-left: 2.1rem;
		font-size: 114%
	}
}

.exit-block {
	max-width: 50rem
}

.btn-exit {
	margin-top: 5.4rem;
	width: 35.9rem;
	max-width: 100%
}

.empty-orders {
	&__icon {
		margin-top: 40px;
		text-align: center;
	}
}

.box-paging {
	margin: 1.6rem 0 0;
}

.box-paging ul {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.box-paging li {
	margin: 0 0 10px;
}

.box-paging a,
.box-paging span {
	font-size: 1.8rem;
	line-height: 1;
	margin: 0 -1px 0 0;
	width: 6rem;
	height: 6rem;
	color: $text-color;
	border: 1px solid #CDCDCD;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: color .5s ease, background .5s ease;
}

.box-paging a:hover {
	color: #fff;
	background: $text-color
}

.box-paging span {
	color: #fff;
	background: $text-color
}

.box-paging span.dots {
	border: 1px solid #CACACA;
	font-size: 1.6rem;
	background: no-repeat;
	color: #120C00;
}

.box-paging .next,
.box-paging .prev {
	font-size: 1.6rem;
}

.box-paging .next.disabled,
.box-paging .prev.disabled {
	display: none;
}

.section-package-service {
	padding: 6.9rem 0 8rem
}

.package-service {
	margin: 0 -3rem;
	display: flex;
	flex-wrap: wrap;


	&__img {
		padding: 2rem 3rem;
		width: 50%
	}
	&__img-title {
		font-size: 2.4rem;
		font-weight: 500;
		margin-bottom: 5.3rem
	}
	ul {
		list-style: none
	}

	&__note {
		margin-top: 15px;
		font-size: 1.6rem;
		color: #808080
	}


}

.btn_add-project-2 {
	width: 46rem;
	max-width: 100%
}

.section-more-articles {
	margin: 12.6rem 0 11.6rem;
}

.section-career-about {
	padding: 9rem 0 11rem
}

.info-block {
	margin-top: 15px;
	font-size: 3rem;
	font-weight: 500;
	overflow: hidden;

	&:before {
		content: '';
		display: block;
		height: 0.4rem;
		width: 9rem;
		margin-bottom: 1.3rem;
		background: $color-active
	}
}

.section-text-about {
	padding: 12rem 0
}

.section-about-video {
	padding: 10.8rem 0 2rem
}

.info-block_about {
	margin-top: 4.2rem;
	max-width: 141rem;
	line-height: 1.3;

	&:before {
		margin-bottom: 5px;
	}
}

.faq {
	ul {
		list-style: none
	}

	ol,
	ul {
		margin: .2rem 0 0 1.2rem;

		li {
			margin-bottom: 1.4rem
		}
	}

	ul li {
		position: relative;
		padding-left: 17px;

		&:before {
			content: '';
			display: block;
			background: $text-color;
			width: 3px;
			height: 3px;
			border-radius: 50%;
			position: absolute;
			left: 0;
			top: 1.2rem;
		}
	}
}

.career-list {
	&__content {
		display: flex;
	}

	&__text {
		width: calc(100% - 40rem)
	}

	&__aside {
		padding-top: 3.5rem;
		width: 40rem
	}

	&__item+&__item {
		margin-top: 3.4rem
	}

	&__value {
		margin-top: 15px
	}
}

.form-career {
	background: #FCFAF1;
	padding: 4.2rem 4.2rem 3.8rem;

	&__item {
		line-height: 1.5;
		margin-bottom: 20px;
		display: flex;
		align-items: center;

		i {
			font-size: 140%;
			flex-shrink: 0;
			width: 4rem;
			margin-right: 10px
		}

		span {
			font-weight: 500
		}
	}

	.btn {
		margin-top: 2rem;
		width: 100%
	}

	&__form {
		margin-top: 7.3rem
	}
	.alert-form {
		display: block;
	}
	.alert-form__text, .alert-form__img {
		width: 100%
	}
	.alert-form__img {
		margin-top: 4rem;
		img {
			max-width: 15rem
		}
	}
}

.more-news {
	margin-top: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	.navs-project-slider {
		margin-top: 0
	}

	.btn {
		margin-right: 2.5rem
	}
}

.section-career-clients {
	padding: 9rem 0 5rem;
	background: #FCFAF1;
	&_2 {
		background: #fff;
	}
}

.client__item {
	padding: 1rem;
	align-items: center;
	justify-content: center;
	height: 10rem;
	display: flex !important;

	img {
		transition: opacity .6s ease;
		vertical-align: top;
		max-height: 10rem;
	}
	.hover-img {
		opacity: 0;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, - 50%);
	}
	&:hover {
		.hover-img {
			opacity: 1;
		}
		.default-img {
			opacity: 0
		}
	}
}
.client__img {
	position: relative;
	display: inline-block;
}
.clients-slider {
	.article-inner-slider-navs {
		justify-content: flex-start;
	}

	.counts-slider {
		order: 2
	}
}

.clients-slider-navs {
	margin-top: 7rem;
}

.section-about-clients {
	padding-bottom: 19.3rem;
	position: relative;
	z-index: 1
}

.about-clients {
	top: -98px;
	padding: 9.8rem 5% 100px 5.8rem;
	background: #fff;
	color: $text-color;
	max-width: 114rem;
	position: relative;

	.h2 {
		margin-right: 5%;
		line-height: 130%;
		margin-bottom: 1.9rem
	}

	.clients-slider {
		margin-top: 7.1rem
	}
}

.section-dark .about-clients .h2 {
	color: $text-color;
}

.section-counts {
	padding: 27.4rem 0 37.3rem;
	background: #FCFAF1;
}

.list-counts {
	margin: 0 -4.5rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
}

.list-counts__item {
	border-top: 2px solid #000;
	padding: 2.8rem 0 0;
	margin: 0 4.5rem;
	margin-bottom: 2rem;
	width: calc(33.333% - 9rem);
	text-align: center;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.list-counts__count {
	font-weight: 500
}

.list-counts__count-value {
	font-size: 10rem;
	font-weight: 500;
}

.list-counts__title {
	padding-left: 2.5rem;
	text-align: right;
	max-width: 19rem
}

.section-team {
	padding: 14rem 0 3rem
}

.team {
	margin: 0 -40px;
	display: flex;
	flex-wrap: wrap;

	&__inner {
		max-width: 40.4rem
	}

	&__item {
		width: 33.333%;
		padding: 0 40px;
		margin-bottom: 6rem;
		border-right: 1px solid $line;

		&:nth-child(3n - 1) {
			.team__inner {
				margin-left: auto;
				margin-right: auto
			}
		}

		&:nth-child(3n) {
			border-right-width: 0;

			.team__inner {
				margin-left: auto;
			}
		}
	}

	&__img {
		margin-bottom: 4.3rem;
		overflow: hidden;
		border-radius: 50%;

		img {
			vertical-align: top;
			width: 100%
		}
	}

	&__title {
		font-size: 120%;
		font-weight: 500
	}

	&__position {
		margin-top: 1.6rem;
		overflow: hidden;
		color: $color2;
		font-size: 1.6rem
	}
}

.more-team {
	margin-top: -.6rem;

	.btn {
		min-width: 25.8rem
	}
}

.expanded {
	.team__item {
		display: block;
	}

	.btn-more-team {
		background: $text-color;
		color: #fff;

		i {
			@extend .icon-remove;
		}
	}

}


.section-contacts {
	padding-bottom: 14rem
}

.contacts-item {
	a {
		color: inherit;
	}

	&__icon {
		font-size: 2.4rem;
		width: 2.4rem;
		margin-right: 2.3rem;
		display: inline-block;
	}

	&__item {

		display: flex;
	}

	&__item+&__item {
		margin-top: 3.1rem
	}

	&__title+&__title {
		margin-top: 15px
	}
}

.contacts-list {
	.faq__body {
		padding-top: 0;
		padding-bottom: 3.7rem
	}
}

.article-main_politica {
	padding-bottom: 12rem
}
.new-projects__mob-title {
	display: none;
}
.section-cases-slider-2 {
	padding: 2.7rem 0 8.3rem;
	background: #F8F8F8;
}
.case-wrap-slider {
	max-width: 142.8rem;
	margin: 0 auto;
}
.text-case {
	margin-top: 3.3rem;
	&__title {
		font-size: 3rem;
		font-weight: 500;
		margin-bottom: 2.7rem
	}
	&__desription {
		font-size: 2rem;
		line-height: 1.4;
		p {
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}
@media screen and (max-width: 1599px) {

	.table-spare {
		font-size: 1.8rem
	}

	.section-more-articles {
		margin: 6rem 0 5.6rem;
	}

	.list-counts__count-value {
		font-size: 8rem;
	}

	.section-counts {
		padding: 12.4rem 0 24.3rem;
	}

	.section-about-clients {
		padding-bottom: 12.3rem;
	}

	.section-team {
		padding: 11rem 0 3rem;
	}

	.alert-form img {
		max-height: 10em
	}
	.created-projects-link__edit {
		margin-right: 0
	}
	.created-projects-link {
    		padding: 2rem 2.6rem;
    }
}

@media screen and (max-width: 1366px) {
	.exellences__item {
		padding-right: 6.9rem;
	}

	.exellences__item:nth-child(even) {
		padding-right: 0;
		padding-left: 6.9rem;
	}

	.main-block-form__inner {
		margin: 0 -1rem;
	}

	.main-block-form__inner .box-field {
		padding: 0 1rem;
	}

	.list-counts__count-value {
		font-size: 7rem;
	}

	.list-counts__title {
		font-size: 1.6rem
	}

	.info-block {
		font-size: 2.6rem;
	}

	.section-career-about {
		padding: 7rem 0 9rem;
	}

	.create-project-block {
		padding: 3.3rem 3.9rem 2.2rem 3.9rem;
	}
}

@media screen and (max-width: 1280px) {

	.box-field_project,
	.box-field_project-checkbox {
		width: 100%;
		max-width: 100%;
	}

	.floating-arrow {
		font-size: 1.7rem;
		height: 11rem;
		width: 11rem;
	}

	.section-consultation .h2 {
		font-size: 4rem;
		margin-bottom: 4.1rem;
	}

	.box-field_project-date {
		width: 100%
	}

	.create-project__details {
		width: 50.5%;
	}

	.create-project__info {
		width: 49.5%;
	}
	.created-projects-link {
		flex-wrap: wrap;
	}
	.created-projects-link__btns {
        margin-left: 0;
    }
    .created-projects-link__title {
    	margin-right: 1.5rem
    }
}

@media screen and (max-width: 1023px) {
	.create-project {
		display: block;
	}

	.create-project__details {
		width: 100%
	}

	.create-project__info {
		padding: 30px 0 0;
		width: 100%
	}

	.footer {
		.nav-inner>ul>li .menu-dropdown a {
			padding: 13px 0;
		}
	}

	.list-counts__item {
		width: calc(50% - 5rem);
		margin-bottom: 4rem;
		max-width: 40rem
	}

	.team__item {
		width: 50%;
	}

	.career-list__content {
		display: block;
	}

	.career-list__text {
		width: 100%;
		padding-right: 0
	}

	.career-list__aside {
		width: 100%;
		margin-top: 25px;
		max-width: 40.4rem
	}

	.section-career-about {
		padding: 7rem 0 3rem;
	}

	.section-contacts {
		padding-bottom: 0rem;
	}

	.projects__title {
		font-size: 2.1rem;
	}

	.info-block {
		font-size: 2rem;
	}

	.new-projects__item {
     min-height: 0;
    }
    .exellences__title {
	    font-size: 2.8rem;
	}
    .exellences__item {
    	padding-bottom: 7rem
    }
}

@media screen and (max-width: 767px) {
	.categories {
		margin: 0;

		&__item {
			width: 100%;
			margin: 0 0 2.6rem;
		}
	}

	.categories__head {
		min-height: 21.5rem
	}

	.section-exellences {
		padding: 2rem 0 3rem;
	}

	.exellences {
		margin-top: 3.9rem;
	}

	.exellences__item {
		width: 100%;
		padding: 0 0 6px;
		border-bottom: 1px solid $line;
		margin-bottom: 1.9rem;

		&:last-child {
			margin-bottom: 0;
			border-bottom-width: 0;
			padding-bottom: 0
		}
	}

	.exellences__count {
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}

	.exellences__title {
		font-size: 1.8rem;
		margin-bottom: .5rem;
	}

	.exellences__description {
		font-size: 1.6rem;
		line-height: 133%;
	}

	.exellences__item:nth-child(even) {
		padding-left: 0;
	}

	.exellences:before {
		display: none;
	}

	.main-block-form__inner {
		margin: 0;
		display: block;
	}

	.main-block-form__inner .box-field {
		padding: 0;
		flex: 0 0 auto
	}

	.alert-form {

		display: block;
		flex-direction: column;

		&__text {
			width: 100%
		}

		&__img {
			margin-top: 3.5rem;
			width: 100%;
			text-align: left;

			img {
				max-height: none;
			}
		}

	}

	.alert-text {
		font-size: 1.6rem;
		line-height: 1.3;
	}

	.product-about-tabs {
		padding-left: 0;
		width: 100%;
		padding-top: 0;
		line-height: 1.3
	}

	.table-spare-mob {
		font-size: 1.6rem;
		line-height: 1.5;

		p {
			padding: 0;
			display: inline;
		}

		&__head {
		    padding: 1.2rem 1rem 1.2rem;
		    margin-bottom: .7rem;
		    background: #fafafa;
		    font-size: 1.6rem;
		    font-weight: 500;
		    text-align: center;
		}

		&__line {
			padding: .7rem 0
		}
		&__block {
			margin-bottom: 10px
		}
	}
	.section-create-project {
		margin-top: 4.2rem;
		padding: 3.1rem 0 4rem;

		.h2 {
			font-size: 2rem;
		}
	}

	.floating-arrow {
		font-size: 1.6rem;
		height: 8rem;
		width: 8rem;
	}

	.projects__title {
		font-size: 1.6rem;
		line-height: 1.5
	}

	.table-spare-mob__line {
		.icon-minus {
			font-size: 25%
		}
	}

	.section-consultation {
		.wrapper {
			padding-top: 4.2rem;
			padding-bottom: 3rem;
		}

		.h2 {
			font-size: 2rem;
			margin-bottom: 2.5rem;
		}
	}

	.main-block-form__inner .box-field__button {
		margin-top: 40px
	}

	.main-block-form__inner .btn {
		height: 5rem;
		line-height: 5rem;
	}

	.box-paging a,
	.box-paging span {
		font-size: 1.6rem;
		width: 5.2rem;
		height: 5.2rem;
	}

.section-package-service {
    padding: 2.9rem 0 0rem;
}
	.package-service {
		&__info {
			padding-right: 0;
			width: 100%;
		}

		&__img {
			margin-bottom: 25px;
			padding: 0;
			width: 100%;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.section-more-articles {
		margin: 3rem 0 2.6rem;
	}

	.section-counts {
		padding: 70px 0 100px;
	}

	.list-counts {
		margin: 0
	}

	.list-counts__item {
		width: 100%;
		margin: 0 0 4rem;
		max-width: 40rem;
	}

	.list-counts__count-value {
		font-size: 5rem;
	}

	.about-clients {
		padding: 39px 19px 20px 22px;

		.h2 {
			font-size: 2rem
		}
	}

	.team {
		margin: 0 -20px;
	}

	.team__item {
		padding: 0 20px;
		margin-bottom: 40px
	}

	.team__item:nth-child(3n) {
		border-right-width: 1px;
	}

	.team__item:nth-child(2n) {
		border-right-width: 0;
	}

	.team__title {
		font-size: 1.6rem
	}

	.team__position {
		font-size: 1.4rem
	}

	.more-news {
		flex-direction: column-reverse;
		align-items: flex-start;
	}

	.more-news .btn {
		margin: 2.5rem 0 0;
		padding: 0 4.5rem;
		font-size: 1.6rem;
		letter-spacing: .01rem;

		i {
			font-size: 1rem;
		}
	}

	.section-about-clients {
		padding-bottom: 70px;
	}

	.info-block {
		font-size: 1.6rem;
	}

	.section-text-about {
		padding: 8rem 0 3rem;
	}

	.section-about-video {
		padding: 7rem 0 2rem;
	}

	.article-main_politica {
		padding-bottom: 6rem
	}

	.projects__img {
		margin-bottom: 1.8rem;
	}

	.career-list__item+.career-list__item {
		margin-top: 1.8rem;
	}

	.career-list__value {
		margin-top: 1rem;
	}

	.career-list__aside {
		margin-top: 0;
		padding-top: 1.2rem;
	}

	.form-career__item i {
		font-size: 197%;
	}

	.form-career {
		padding: 2.7rem 3rem 3.9rem;
	}

	.form-career__form {
		margin-top: 3.4rem;
	}

	.form-career .btn {
		margin-top: 1.2rem;
		height: 5rem;
		line-height: 5rem;
	}

	.section-career-clients {
		padding: 3rem 0 5rem;

		.h2 {
			font-size: 2rem
		}
	}

	.client__item {
		padding: .5rem;
		height: 8rem;
		img {
			max-height: 8rem;
		}
	}

	.clients-slider-navs {
		margin-top: 2rem;
	}

	.section-career-about {
		padding: 3rem 0 2rem;
	}

	.info-block:before {
		width: 6.3rem;
		margin-bottom: 1.5rem;
	}

	.create-project-block {
		padding: 3rem 2.2rem 1.2rem
	}

	.section-video-product {
		padding-bottom: 0
	}

	.create-project__head {
		font-size: 1.8rem;
		margin-bottom: 2.6rem;
	}
	.video-el {max-height: calc(100vh - 8rem);
	}
	.video-el img {
		min-height: 18.9rem;
		object-position: 50% 50%;
		object-fit: cover
	}
	.box-field_label {
		min-height: 0;
		margin-bottom: 0.7rem;
	}
	.create-project__submit .btn {
		height: 5rem;
		font-size: 1.6rem
	}
	.create-project__submit {
	    margin-top: 32px;
	}
	.new-one-project {
	    padding: 2.1rem 2rem;
	    font-size: 1.6rem;
	}
	.new-projects__item {
		flex-wrap: wrap;
		align-items: center;
	}
	.new-projects__img {
		width: 43%;
	}
	.new-projects__head {
		width: 100%
	}
	.new-projects__mob-title {
		display: block;
		width: 57%;
		padding-left: 2.4rem;
	}
	.new-projects__title {
		font-size: 1.6rem
	}
	.new-projects__head  {
		font-size: 1.4rem;
		padding: 13px 22px 17px;
		.new-projects__title {
			display: none;
		}
	}
	.new-projects__details {
		margin-top: 0
	}
	.new-projects__text {
	    font-size: 1.4rem;
	    line-height: 1.3;
	    max-width: 28rem;
	}
	.new-projects__detail {
	    font-size: 1.4rem;
	    line-height: 1.3;
    }
    .new-projects__btn {
	    margin-top: 10px;
	}
	.new-projects__btn .btn {
	    height: 5rem;
	    line-height: 5rem;
	    font-size: 1.6rem;
	}
	.section-more-projects {
		padding: 3.3rem 0 30px;
		.h2 {
			font-size: 2rem
		}
	}
	.created-projects-link {
		flex-wrap: wrap;
		    padding: 30px 12px;
	}
	.created-projects-link__title {
		width: 100%;
		margin-right: 0;
		margin-bottom: 1.6rem
	}
	.created-projects-link__btns {
		margin-left: 0
	}
	.created-projects-link__remove, .created-projects-link__edit {
		font-size: 1.4rem
	}
	.created-projects-link__remove i, .created-projects-link__edit i {
	    margin-right: 12px;
	}
	.created-projects-link__remove {
	    margin-right: 2.5rem;
	}
	.created-projects-link__edit {
	    margin-right: 0;
	}
	.created-projects-link__more {
	    top: 1.9rem;
	    font-size: 1.6rem;
	    right: .9rem;
		transform: none
	}
	.created-projects-link__edit {
	    padding: 0.9rem 2.4rem;
	}
	.new-projects__item {
    	min-height: 19.5rem;
    }
    .box-field_project {
		[type="text"], [type="tel"], [type="email"], [type="password"] {
		    height: 5rem;
		}
	}
	.package-service {
	    margin: 0 ;
	}
	.package-service__img-title {
	    font-size: 2rem;
	    margin-bottom: 0.3rem;
	}
	.new-projects {
		max-height: none
	}
	.table-spare {
		height: 0;
		overflow: hidden;
	}
	.section-cases-slider-2 {
		background: #fff
	}
	.section-cases-slider-2 {
	    padding: 3.7rem 0 0rem;
	}
	.article-inner-slider {
		margin: 0;
	}
	.article-inner-slider__item {
		padding: 0
	}
	.text-case {
		margin-top: 2.3rem;
		&__title {
			font-size: 1.8rem;
			margin-bottom: 1.7rem
		}
		&__desription {
			font-size: 1.6rem;
			line-height: 2.8rem;
		}
	}
}
@media screen and (min-width: 1024px) {
	.new-projects__mob-title {
		display: none;
	}
}
@media screen and (min-width: 1024px) {
	.block-img-4_half {
		align-items: center;

		.block-img-4__description,
		.block-img-4__img {
			width: 50%
		}
	}
}

@media screen and (min-width: 1280px) {
	.section-text-about {
		.h2 {
			width: 50%
		}
	}
}