.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  color: #3E424D;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  font-size: 1.5rem;
  line-height: 1rem;
  position: absolute;
  width: 31rem;
  border-radius: .8rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  border: 1px solid #C4C9CE;
  .jq-selectbox__select {
    color: $text-color;
  }
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(.23, 1, .32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(.23, 1, .32, 1)
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px)
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0
}

.flatpickr-calendar.hasTime .flatpickr-time {
  padding: 1.6rem 5.5rem 1.5rem;
  background: #F8FAFF;
  margin-top: .3rem
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
  display: none;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff
}

.flatpickr-calendar:focus {
  outline: 0
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.flatpickr-months .flatpickr-month {
  margin-top: 1.8rem;
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  font-size: 2rem;
  line-height: 1;
  height: 4rem;
  text-align: center;
  position: relative;
  user-select: none;
  overflow: hidden;
  flex: 1
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;padding: 1rem 1.5rem 1rem;
  position: absolute;
top: 1.6rem;
    height: 3.6rem;
  z-index: 3;
  color: #6387C9;
  fill: #6387C9;

}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0
}


.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  right: 0
    /*
      /*rtl:end:ignore*/
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
    width: 1.8rem;
    height: auto;
    path {
      fill:  #6387C9;
    }
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill .1s;
  transition: fill .1s;
  fill: inherit
}

.numInputWrapper {
  position: relative;
  height: auto;
  &:first-child {
    &:before {
      content: 'Hour';
      display: block;
      font-size: 12px;
line-height: 12px;
margin-bottom: 4px;
color: #6387C9;
text-align: left
    }
  }
  &:nth-child(3) {
    &:before {
      content: 'Minutes';
      display: block;
      font-size: 12px;
line-height: 12px;
margin-bottom: 4px;
color: #6387C9;
text-align: left
    }
  }
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block
}

.numInputWrapper input {
  width: 100%
}

.numInputWrapper input::-ms-clear {
  display: none
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 1;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1)
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2)
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute
}
.numInputWrapper span.arrowUp,
.numInputWrapper span.arrowDown {
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 1.6rem);
      width: 2.7rem;
      top: 1.6rem;
      font-size: 1.2rem

}
.numInputWrapper span.arrowUp {
  right: 0;
}



.numInputWrapper span.arrowDown {
  left: 0;
}








.flatpickr-current-month {
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: .5ch;
  padding: 0
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05)
}

.flatpickr-current-month {
  .numInputWrapper {
  display: inline-block;
  font-size: inherit;
}
.numInputWrapper:nth-child(3):before {
  display: none;
}
}


.flatpickr-current-month span {
  display: none;
}
.flatpickr-current-month input.cur-year {
  background: transparent !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 .5rem;
border: 0 !important;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 700;
font-size: inherit;
line-height: 1;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  pointer-events: none;
  width: 6.3rem
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none
}

.flatpickr-current-month  {
  .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 .5ch;
  font-weight: 700;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
  pointer-events: none;
  display: inline-block;
  select {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  }
}
.jq-selectbox__select, .ms-choice, .flatpickr-current-month .flatpickr-monthDropdown-months {
  font-weight: 700;
font-size: inherit;
line-height: 1;
  padding: 0;
  pointer-events: none;
      height: auto;
      overflow: visible;
      border: 0;
      padding: 0;
      color: inherit;
}
.jq-selectbox__select-text {
  overflow: visible;
}
.jq-selectbox__trigger,.ms-choice > div.icon-caret {
  display: none;
}
}

.flatpickr-current-month {
  .ms-choice span {
  display: inline-flex;
  align-items: center;
  position: static;
  transform: none;
  &:before {
    display: none;
  }
  .arrowUp,.arrowDown {
    display: none;
  }
}
.ms-parent {
  width: auto !important;
    max-width: 100%;
    display: inline-block;
}
}
.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05)
}
.flatpickr-current-month input.cur-year, .flatpickr-current-month .ms-choice {
  height: 3rem;
  line-height: 2.8rem;
  background: transparent;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
    height: 3.2rem;
  align-items: center;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1
}

span.flatpickr-weekday {
  cursor: default;
  background: transparent;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
padding: 2px 0;
  flex: 1;
  font-weight: 400;
  width: 3.8rem
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0
}

.flatpickr-days {
  margin-top: 1.3rem;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 100%
}

.flatpickr-days:focus {
  outline: 0
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 100%;
  min-width: 100%;
  max-width:100%;
  justify-content: flex-start;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0, 0, 0);
  opacity: 1
}

.dayContainer+.dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #3E424D;
  cursor: pointer;
  font-weight: 400;
  flex-basis: calc(14.2857143% - .4rem);
  width: calc(14.2857143% - .4rem);
  height: calc(14.2857143% - .2rem);
  line-height: 3.4rem;
       margin: .2rem 0.2rem;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6
}

.flatpickr-day.today {
  border-color: #959ea9
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $color-active;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: $color-active;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0
}

.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
color: #C4C9CE;
  background: transparent;
  border-color: transparent;
  cursor: default
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
color: #C4C9CE;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7
}

.flatpickr-day.hidden {
  visibility: hidden
}

.rangeMode .flatpickr-day {
  margin-top: 1px
}

.flatpickr-weekwrapper {
  float: left
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none
}

.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%
}

.flatpickr-rContainer {
  display: block;
  padding: .8rem 2rem;
  box-sizing: border-box;
  width: 100%
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  display: flex
}


.flatpickr-time .numInputWrapper {
  width: 31%;
  width: calc(50% - 1rem);
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:before {
  color: #C4C9CE;
  @extend .icon-next;
  font-family: icomoon;
}

.flatpickr-time .numInputWrapper span.arrowDown:before {
  color: #C4C9CE;
  @extend .icon-prev;
  font-family: icomoon;
}
.flatpickr-time .numInputWrapper span.arrowUp:hover,
.flatpickr-time .numInputWrapper span.arrowDown:hover {
  background:  transparent;
  
  &:before {
    color: #000;
  }
} 
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;

  border: 1px solid #B0BACC;
border-radius: 4px;
  text-align: center;
  margin: 0;
  padding: 0 3rem 0 3rem;
  height: 5rem;
  width: 100%;
  font-size: 1.6rem;
  line-height: inherit;
  color: $text-color;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: 400
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: 3.4rem;
  color: #393939;
  font-weight: bold;
  width: 4%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}
.flatpickr-time .flatpickr-time-separator {
  padding: 1rem 1.6rem 0;
}
.flatpickr-time .flatpickr-am-pm {
 display: none;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: transparent;
}

.flatpickr-input[readonly] {
  cursor: pointer
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0)
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}