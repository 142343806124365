
.window-open {
    position: absolute;
    left: 0;
    top: -200%;
    right: 0;
    overflow: hidden;
    height: 100%;
}
.popup {
    max-width: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border-radius: 0;
    background: #FFFFFF;

}
.popup-sucsess {
    width: 84.2rem
}
.popup-inner {
    padding: 6rem;
}
@media screen and (max-width: 767px) {
    .popup-inner {
        padding: 6rem 2.6rem 4rem
    }
    .popup-head {
        font-size: 2rem
    }
}