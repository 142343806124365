.page-osnova {
	.section-consultation {
		color: #fff;
		background: linear-gradient(270deg, rgba(146, 81, 237, 1) 0%, rgba(84, 21, 188, 1) 100%);
	}

	.floating-arrow {
		background: #5F01D2;
		color: #fff
	}

	.section-create-project {
		background: #F8F8F8;
	}

	.new-projects__item {
		border-radius: 1.6rem;
		border-color: $line;
		background: #FFF;
		overflow: hidden;
	}

	.new-projects__item {
		align-items: flex-start;
	}

	.new-projects__detail {
		display: none;
	}

	.new-projects__detail:first-child,
	.new-projects__detail:nth-child(2),
	.new-projects__detail:nth-child(3) {
		display: flex;
	}

	.new-projects__item.expanded .new-projects__detail {

		.new-projects__detail,
		.new-projects__detail:nth-child(4),
		.new-projects__detail:nth-child(5) {
			display: flex;
		}
	}

	.create-project_2 .new-projects__img {
		background: none
	}

	.new-projects__more {
		color: #5F01D2;
	}

	.box-paging span,
	.box-paging a:hover {
		background: #5F01D2
	}
	.box-paging span.dots {
		background: transparent;
	}
	.alert-form {
		background: none
	}
	.img-def {
		display: none;
	}
	.img-osnova {
		display: inline-block;
	}
	.psevdoborder {
    border-radius: 1.6rem;
        border: 1px solid #B5B5B5;
            background: #FCFCFC;
            padding-bottom: .6rem;
            padding-top: .6rem;
		 textarea {    
		 	padding-top: 1rem;
            padding-bottom: 1rem;
    border: 0;
}
&.error {
	border-color: #FF5050;
}
	}

	@media screen and (max-width: 767px) { 
		.new-projects__item {
			align-items: center;
			border-radius: .8rem
		}
		.section-more-projects {
		    padding: 4rem 0 3.9rem;
		}
	}
}


.price-table {
	&__row {
		display: flex;
		justify-content: space-between;

		&:nth-child(2n+1) {
			background: #F8F8F8;
		}

		&-head,
		&-head:nth-child(2n+1) {
			background: #E7D4FF;
			font-weight: 500
		}
	}

	&__row-mainhead {
		font-weight: 500
	}

	&__col {
		padding: 1.7rem 1.8rem;
		width: calc(100% - 14.4rem);
		word-wrap: break-word;
	}

	&__col+&__col {
		border-left: 1px solid $line;
		width: 14.4rem;
		flex-shrink: 0
	}
}

.prices+.prices {
	margin-top: 40px
}

.prices-title {
	font-size: 2.4rem;
	font-weight: 500;
	margin-bottom: 15px
}

.cases {
	.projects__img:before {
		display: none;
	}

	.projects__img {
		background: none
	}

	.projects__img img {
		position: static;
		transform: none;
		vertical-align: top;
		max-height: none;
	}

	.projects__head {
		padding: 0 3.4rem 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1 1 100%;
	}

	.projects__item {
		border-radius: 1.6rem;
		background: #F8F8F8;
		flex: 1 1 100%;
		display: flex !important;
		flex-direction: column;
	}
	& > div {
		
		flex: 1 1 100%;
		display: flex !important;
		flex-direction: column;
	}
}

.projects__place {
	margin: 0 0 2.8rem;
	padding: 0 1.5rem;
    color: #B5B5B5;
    text-transform: uppercase;
    line-height: 1.05;
    font-size: 1.6rem;
    letter-spacing: .1em;
	display: inline-block;
	border-left: 1px solid #5F01D2;
	border-right: 1px solid #5F01D2;
}

.projects__read {
	margin-top: 4rem;
	color: #5F01D2;
	display: inline-flex;
	align-items: center;

	i {
		margin-left: 1.5rem;
		font-size: 36%;
		transition: margin-left .5s ease;
	}

	&:hover i {
		margin-left: 3rem
	}
}

.cases-slider {
	.slick-track {
		display: flex;

		&:before,
		&:after {
			display: none;
		}
	}

	.slick-slide {
		display: flex;
		flex-direction: column;

		&>div {
			flex: 1 1 100%;
			display: flex;
			flex-direction: column;
		}
	}
}

.section-more-cases {
    padding: 5rem 0 14.2rem;
}
	@media screen and (max-width: 1660px) {
		.price-table {
			font-size: 1.7rem
		}
	}
	@media screen and (max-width: 1023px) {
.projects__read {
    margin-top: 3rem;
    }
.projects__place {
    margin: 0 0 2.1rem;
    font-size: 1.5rem;
    }
    .section-more-cases {
    padding: 5rem 0 9.2rem;
}
}

	@media screen and (max-width: 767px) {
.section-more-cases {
    padding: 3rem 0 4rem;
}
.cases .projects__head {
    padding: 0 1.7rem 1.8rem;
    }
    .projects__read {
    margin-top: 1.5rem;
}

@media screen and (max-width: 359px) {
	.price-table__col {
		width: calc(100% -  13.7rem);
	}
	.price-table__col + .price-table__col {
		width: 13.7rem
	}
}
}