

.jq-file {
	width: 100%;
	display: inline-block;
    position: relative;
    overflow: hidden;
    font-size: 1.6rem;
    height: 5.7rem;
    display: flex;
    align-items: center;
    border: 1px solid $line;
}
.jq-file input {
	cursor: pointer;
	height: auto;
	line-height: 1em;
    width: 128px;
        position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0px;
    padding: 0px;
}

.jq-file__name {
-moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 50px 0 16px;
    color: $color2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    background: transparent;
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    width: calc(100% - 50px);
}
.jq-file__browse {
	position: absolute;
	color: $color2;
    text-align: center;
    cursor: pointer;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5rem;
    @extend .icon-load;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -999999px;
    font-size: 0;
    font-family: icomoon;
    &:before {
    font-size: 24px;
    	letter-spacing:1px;
    }
}
.jq-file:hover .jq-file__browse {
    opacity: 0.7;
}

.changed .jq-file__name {
	color: $text-color;
}


.jq-selectbox {
	vertical-align: middle;
	cursor: pointer;
	position: relative;
    width: 100%;
	font-size: 1.6rem;
	font-weight: 400;
	&.opened {
		z-index: 101
	}
}
.jq-selectbox__select {
	height: 5.6rem;
	padding: 1.6rem 7.2rem .7rem 3rem;
	position: relative;

	background: #FCFCFC;
	border: 1px solid $line;
}

.jq-selectbox select, .jq-select-multiple select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    z-index: -100
}

.jq-selectbox select [label="/"] {
	display: none;
}
.select_with-select-first .jq-selectbox__select{
    color: #5c6266;
}

.jq-selectbox__select-text {
	display: block;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	&.placeholder {
		color: #cecccc;
	}
}
.jq-selectbox__trigger {
	position: absolute;
	top: 0;
	right: 0;
	width: 38px;
	height: 100%;

}
.jq-selectbox__trigger-arrow {
	position: absolute;
	top: 1.6rem;
	right: 2.8rem;
	width: 1.2rem;
	height: 1.2rem;
	overflow: hidden;
	border-bottom: 1px solid $text-color;
	border-left: 1px solid $text-color;
	transform: rotate(-45deg);
	transform-origin: center;
	transition: all .3s ease-in
}


.jq-selectbox__dropdown {
	top: 100%;
    margin-top: -1px;
	width: 100%;
	box-sizing: border-box;
	padding: 0;
	overflow: hidden;
	position: absolute;
	z-index: 100;
	position: relative;

	background: #FCFCFC;
	border: 1px solid $line;
}

.jq-selectbox__search {
	margin: 5px;
}
.select_number-persons .jq-selectbox__search{
    display: none!important;
}
.jq-selectbox__search input {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 5px 27px 6px 8px;
	outline: none;
	border: 1px solid #CCC;
	border-radius: 3px;
	color: #333;
	-webkit-appearance: textfield;
}
.jq-selectbox__search input[type=search]{display:none;}
.jq-selectbox__search input::-webkit-search-cancel-button,
.jq-selectbox__search input::-webkit-search-decoration {
	-webkit-appearance: none;
}
.jq-selectbox__not-found {
	margin: 5px;
	padding: 5px 8px 6px;
	background: #F0F0F0;
	font-size: 13px;
}
.jq-selectbox ul {
	margin: 0;
	max-height: 175px;
	list-style: none;
	overflow: hidden auto;
}
.jq-selectbox li {
	padding: .8rem 2rem .9rem 3rem;
	transition: color .5s ease
}
.jq-selectbox li:hover {
	background: #F3F1D9;
}
.jq-selectbox li.selected {
	background: #F3F1D9;
	color: $text-color;
}
.jq-selectbox li.disabled {}
.jq-selectbox li.disabled:hover {}
.jq-selectbox li.optgroup {
	font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
	background: none;
	color: #231F20;
	cursor: default;
}
.jq-selectbox li.option {
	padding-left: 25px;
}
.error~ .jq-selectbox__select {
	border-color: #FF5050
}
.jq-select-multiple {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 1px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,0.1);
	background: #FFF;
	color: #333;
	font: 14px/18px Arial, sans-serif;
	cursor: default;
}
.jq-select-multiple.focused {
	border: 1px solid #5794BF;
}
.jq-select-multiple.disabled {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-select-multiple ul {
	margin: 0;
	padding: 0;
}
.jq-select-multiple li {
	padding: 3px 9px 4px;
	list-style: none;
}
.jq-select-multiple li:first-child {
	border-radius: 3px 3px 0 0;
}
.jq-select-multiple li:last-child {
	border-radius: 0 0 3px 3px;
}
.jq-select-multiple li.selected {
	background: #08C;
	color: #FFF;
}
.jq-select-multiple li.disabled {
	color: #AAA;
}
.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
	background: #CCC;
	color: #FFF;
}
.jq-select-multiple li.optgroup {
	font-weight: bold;
}
.jq-select-multiple li.option {
	padding-left: 25px;
}
.jq-selectbox li.disabled {
    color: #cdcdcd;
    pointer-events: none;
}
.jqselect.disabled {
	.jq-selectbox__select-text.placeholder,.jq-selectbox__select-text {
		color: #cdcdcd;
	}
	.jq-selectbox__select, .jq-selectbox__trigger-arrow {
		border-color: #e4e4e4
	}
}

@media screen and (max-width: 767px) {
	.jq-file {
	    height: 5rem;
	}
	.jq-selectbox__select {
	    height: 5rem;
	    padding: 1.4rem 4.7rem .7rem 1.8rem;
    }
    .jq-selectbox__trigger-arrow {
	    top: 1.5rem;
	    right: 1.8rem;
	}
	.jq-selectbox li {
    	padding: .8rem 1.8rem .9rem;
    }
}