.icon-search:before {
  content: "\e922";
}
.icon-arrow:before {
  content: "\e900";
}
.icon-arrow-back:before {
  content: "\e901";
}
.icon-arrow-short:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-caret:before {
  content: "\e904";
}
.icon-case:before {
  content: "\e905";
}
.icon-check:before {
  content: "\e906";
}
.icon-chevron:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-crown:before {
  content: "\e909";
}
.icon-crown-2:before {
  content: "\e90a";
}
.icon-down:before {
  content: "\e90b";
}
.icon-download:before {
  content: "\e90c";
}
.icon-edit:before {
  content: "\e90d";
}
.icon-email:before {
  content: "\e90e";
}
.icon-load:before {
  content: "\e90f";
}
.icon-location:before {
  content: "\e910";
}
.icon-login:before {
  content: "\e911";
}
.icon-look:before {
  content: "\e912";
}
.icon-look_close:before {
  content: "\e913";
}
.icon-minus:before {
  content: "\e914";
}
.icon-next:before {
  content: "\e915";
}
.icon-next-1:before {
  content: "\e916";
}
.icon-place:before {
  content: "\e917";
}
.icon-plus:before {
  content: "\e918";
}
.icon-prev:before {
  content: "\e919";
}
.icon-prev-1:before {
  content: "\e91a";
}
.icon-project:before {
  content: "\e91b";
}
.icon-quotes:before {
  content: "\e91c";
}
.icon-remove:before {
  content: "\e91d";
}
.icon-tel:before {
  content: "\e91e";
}
.icon-up:before {
  content: "\e91f";
}
.icon-warning:before {
  content: "\e920";
}
.icon-warning-square:before {
  content: "\e921";
}
