body.compensate-for-scrollbar {
    overflow: hidden;
}

.fancybox-active {
    //height: auto;
}

.fancybox-is-hidden {
    left: -9999px;
    margin: 0;
    position: absolute !important;
    top: -9999px;
    visibility: hidden;
}

.fancybox-container {
    -webkit-backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: none;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    transform: translateZ(0);
    width: 100%;
    z-index: 99992;
}

.fancybox-container * {
    box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fancybox-outer {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.fancybox-bg {
background: rgba(0, 0, 0, 0.20);

box-shadow: 0px 9px 28px 0px rgba(255, 255, 255, 0.05);

    opacity: 0;
    transition-duration: inherit;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.47, 0, .74, .71);
}
.fancybox-active {
    .main-wrapper {
         filter: blur(10px);   
    }
}
.fancybox-is-open .fancybox-bg {
    opacity: 1;
    transition-timing-function: cubic-bezier(.22, .61, .36, 1);
}
.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
    direction: ltr;
    opacity: 0;
    position: absolute;
    transition: opacity .25s ease, visibility 0s ease .25s;
    visibility: hidden;
    z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
    opacity: 1;
    transition: opacity .25s ease 0s, visibility 0s ease 0s;
    visibility: visible;
}

.fancybox-infobar {
    color: #ccc;
    font-size: 13px;
    -webkit-font-smoothing: subpixel-antialiased;
    height: 44px;
    left: 0;
    line-height: 44px;
    min-width: 44px;
    mix-blend-mode: difference;
    padding: 0 10px;
    pointer-events: none;
    top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fancybox-toolbar {
    right: 0;
    top: 0;
}

.fancybox-stage {
    direction: ltr;
    overflow: visible;
    transform: translateZ(0);
    z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
    overflow: hidden;
}

.fancybox-slide {
    -webkit-backface-visibility: hidden; /* Using without prefix would break IE11 */
    display: none;
    height: 100%;
    left: 0;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 40px;
    position: absolute;
    text-align: center;
    top: 0;
    transition-property: transform, opacity;
    white-space: normal;
    width: 100%;
    z-index: 99994;
}

.fancybox-slide::before {
    content: '';
    display: inline-block;
    font-size: 0;
    height: 100%;
    vertical-align: middle;
    width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
    display: block;
}

.fancybox-slide--image {
    overflow: hidden;
    padding: 44px 0;
}

.fancybox-slide--image::before {
    display: none;
}

.fancybox-slide--html {
    padding: 0;
}

.fancybox-content {

    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 40px;
    position: relative;
    text-align: left;
    vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
    animation-timing-function: cubic-bezier(.5, 0, .14, 1);
    -webkit-backface-visibility: hidden;
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    -ms-transform-origin: top left;
    transform-origin: top left;
    transition-property: transform, opacity;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
    cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
    cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
    cursor: -webkit-grab;
    cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.fancybox-container [data-selectable='true'] {
    cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}

.fancybox-spaceball {
    z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
    height: 100%;
    overflow: visible;
    padding: 0;
    width: 100%;
}

.fancybox-slide--video .fancybox-content {
    background: #000;
}

.fancybox-slide--map .fancybox-content {
    background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
    background: #fff;
}

.fancybox-video,
.fancybox-iframe {
    background: transparent;
    border: 0;
    display: block;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
    left: 0;
    position: absolute;
    top: 0;
}

.fancybox-error {
    background: #fff;
    cursor: default;
    max-width: 400px;
    padding: 40px;
    width: 100%;
}

.fancybox-error p {
    color: #444;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

/* Buttons */

.fancybox-button {
    background: rgba(30, 30, 30, .6);
    border: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 44px;
    margin: 0;
    padding: 10px;
    position: relative;
    transition: color .2s;
    vertical-align: top;
    visibility: inherit;
    width: 44px;
}

.fancybox-button--zoom {
    display: none
}
.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
    color: #ccc;
}

.fancybox-button:hover {
    color: #fff;
}

.fancybox-button:focus {
    outline: none;
}

.fancybox-button.fancybox-focus {
    outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
    color: #888;
    cursor: default;
    outline: none;
}

/* Fix IE11 */
.fancybox-button div {
    height: 100%;
}

.fancybox-button svg {
    display: block;
    height: 20px;
    overflow: visible;
    position: relative;
    width: 20px;
}
.fancybox-button:hover svg {
    fill: $color-active
}
.fancybox-button svg path {
    fill: currentColor;
    stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
    display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
    display: none;
}

.fancybox-progress {
    background: #ff5268;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -ms-transform-origin: 0;
    transform-origin: 0;
    transition-property: transform;
    transition-timing-function: linear;
    z-index: 99998;
}
/* Close button on the top right corner of html content */

.fancybox-close-small {
    border: 0;
    text-align: center;
    color: $text-color;
    cursor: pointer;
    padding: 5px;
    position: absolute;
    background: transparent;
    border: 0;
    right: 3.1rem;
    top: 3.1rem;
    height: auto;
    width: auto;
    z-index: 401;
    font-size: 2.3rem;
    transition: color .3s ease-in;
    &:hover {
        color: $color-active
    }

}




.fancybox-slide--html .fancybox-content {
    padding: 0
}
.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
    overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
    display: none;
}

/* Navigation arrows */

.fancybox-navigation .fancybox-button {
    background-clip: content-box;
    height: 100px;
    opacity: 0;
    position: absolute;
    top: calc(50% - 50px);
    width: 70px;
}

.fancybox-navigation .fancybox-button div {
    padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
    left: 0;
    left: env(safe-area-inset-left);
    padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
    padding: 31px 6px 31px 26px;
    right: 0;
    right: env(safe-area-inset-right);
}


.fancybox-slide {
opacity: 0;
transition: all 0.01s ease;}

.fancybox-slide.fancybox-slide--complete {
opacity: 1!important;
transition: all 0.4s ease;}


/* Loading indicator */

.fancybox-loading {
    animation: fancybox-rotate 1s linear infinite;
    background: transparent;
    border: 4px solid #888;
    border-bottom-color: #fff;
    border-radius: 50%;
    height: 50px;
    left: 50%;
    margin: -25px 0 0 -25px;
    opacity: .7;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 50px;
    z-index: 99999;
}

@keyframes fancybox-rotate {
    100% {
        transform: rotate(360deg);
    }
}

/* Transition effects */

.fancybox-animated {
    transition-timing-function: cubic-bezier(0, 0, .25, 1);
}

/* transitionEffect: slide */

.fancybox-fx-slide.fancybox-slide--previous {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}



/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
    .fancybox-slide {
        padding-left: 6px;
        padding-right: 6px;
    }

    .fancybox-slide--image {
        padding: 6px 0;
    }



    .fancybox-slide--image .fancybox-close-small {
        background: #4e4e4e;
        color: #f2f4f6;
        height: 36px;
        opacity: 1;
        padding: 6px;
        right: 0;
        top: 0;
        width: 36px;
    }

    .fancybox-caption {
        padding-left: 12px;
        padding-right: 12px;
    }


}




.fancybox-slide--html {
    min-width: $min-width
}
.fancybox-slide--video {
        transition: transform 2s ease-in ;
        display: block;

}
.fancybox-slide--video .fancybox-content {
    //transform: scale(0);
    transform-origin: 50% 50%;

}
     .fancybox-slide--video.fancybox-slide--complete .fancybox-content {
      //transform: scale(1);
        transition: transform .5s ease-in !important
    } 



@media screen and (max-width: 560px) { 
.fancybox-close-small {
    right: 2.1rem;
    top: 2.1rem;
    font-size: 2rem;
}
}
@media screen and (max-width: 560px) { 
.fancybox-slide--html {
    padding: 0;
}

.fancybox-is-open .fancybox-bg {
    background: $base-bg;
    opacity: 1
}
}